import React from 'react'
import About from './About'
import Footer from './Footer'

const AboutPage = () => {
  return (
    <>
        <About/>
        <Footer/>
    </>
  )
}

export default AboutPage