import React from "react";
import Service from "./Service";
import Footer from "./Footer";
import Technologies from "./Technologies";

const Frontend = () => {
  return (
    <div>
      <Service />
      <Technologies />

      <div className="container">
        <div>j</div>
      </div>

      <Footer />
    </div>
  );
};

export default Frontend;
