import React from "react";
import "../service.css";
import {
  FaLaptop,
  FaChartLine,
  FaUncharted,
  FaWaveSquare,
} from "react-icons/fa";
function Service() {
  return (
    <>
      {/* <section className=" about my-5">
        <div className="container  ">
          <h2 className="text-center mt-3  " style={{ color: "#ff4d29" }}>
            We provide you with high quality technology services
          </h2>
          <hr />

          <div className="my-5">
            <div className="row my-2">
              <div className="col-sm-12 col-md-6 col-lg-6 col-12">
                <h4>
                  <b style={{ color: "#ff4d29" }}>W</b>eb{" "}
                  <b style={{ color: "#ff4d29" }}>D</b>evelopment
                </h4>
                <p className="p-2 ">
                  Web Design Ledger brings unique insights on how to create
                  stunning web products. Readers can get informed about current
                  tech trends and see insights from top web developers in the
                  field through Q&A interviews. See what the best development
                  tools are, learn about the best cloud computing programming
                  languages, and see how you can make JavaScript testing and
                  deployments more efficient.
                </p>
                <p>
                  We will help you to showcase your business on a clean
                  responsive website. We are the best web development services
                  in India providing a Cost-Effective web services.
                  <b>
                    {" "}
                    We are providing Responsive Web Design & Development
                    Services in various sectors like Corporate, Educational,
                    Hospitality, Rental Services, E-Commerce, Non Governmental
                    Organizations and Trusts.
                  </b>
                </p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-12 ">
                <img
                  src={require("../image/Web.png")}
                  className="img-fluid  "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container  ">
          <div className="my-5">
            <div className="row my-2">
              <div className="col-sm-12 col-md-6 col-lg-6 col-12 ">
                <img
                  src={require("../image/App.png")}
                  className="img-fluid  "
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-12">
                <h4>
                  <b style={{ color: "#ff4d29" }}>O</b>n-demand{" "}
                  <b style={{ color: "#ff4d29" }}>A</b>pp{" "}
                  <b style={{ color: "#ff4d29" }}>D</b>evelopment
                </h4>

                <p className="p-2 ">
                  Technology has impacted our lives in more ways than we can
                  imagine, and smartphones have a huge role to play in this
                  shift. Whether it is about money transfer, shopping online,
                  ordering food, and a lot more, we can do all of these with the
                  swipe of our fingers. All of this to address the evolving
                  customer needs and meet their expectations to get instant
                  services.
                </p>
                <p>
                  And that’s why on-demand applications are becoming popular. As
                  the name suggests, they satisfy the demanding needs of the
                  customers instantly. The current circumstances are pushing
                  entrepreneurs to add more potential to online shopping by
                  including them in the business mix and achieving new heights.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container  ">
          <div className="my-5">
            <div className="row my-2">
              <div className="col-sm-12 col-md-6 col-lg-6 col-12">
                <h4>
                  <b style={{ color: "#ff4d29" }}>E</b>-
                  <b style={{ color: "#ff4d29" }}>C</b>ommerce
                </h4>
                <p>
                  Shopify provides you with a variety of tools to help you to
                  set up and run your business. Depending on the pricing plan
                  that you choose, you can find everything you need to showcase
                  your products online, to process payments, and to make your
                  store work for you.
                </p>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-12 ">
                <img
                  src={require("../image/shopify.png")}
                  className="img-fluid  "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container  ">
          <div className="my-5">
            <div className="row my-2">
              <div className="col-sm-12 col-md-6 col-lg-6 col-12 ">
                <img
                  src={require("../image/uiux.png")}
                  className="img-fluid  "
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-12">
                <h4>
                  <b style={{ color: "#ff4d29" }}>U</b>I/
                  <b style={{ color: "#ff4d29" }}>U</b>X{" "}
                  <b style={{ color: "#ff4d29" }}>D</b>esign
                </h4>
                <p>
                  In this hands-on UI/UX Design Masters Program, you will cover
                  comprehensive approaches to all UI/UX design development
                  stages. You will learn the concepts of UX research, design
                  thinking, UI prototyping and styling, information
                  architecture, usability and testing.
                </p>
                <ul>
                  <li>
                    Collect and analyze data to create data-driven UI designs
                    and user experiences
                  </li>
                  <li>
                    Build an impressive design portfolio with effective and
                    compelling designs that showcase your brand to the world
                  </li>
                  <li>
                    Design digital experiences that bring user satisfaction,
                    user loyalty, and product success
                  </li>
                  <li>
                    Test the usability of a design through usability evaluations
                    and cognitive walk-throughs
                  </li>
                  <li>
                    Earn an industry-recognized course completion certificate
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div class="container">
        <h1 className="text-center mt-3  fw-bold" style={{ color: "#854dff" }}>
          Our Service
        </h1>
        <p className="text-center text-muted">
          IT Solution Strategy Development for your Business
        </p>
        <div class="row my-3 ">
          <div class="col-12 col-sm-6 col-md-4 m-auto">
            <div className="card border-0 shadow text-center my-3 ">
              <div className="card-body service">
                <i>
                  <FaLaptop />
                </i>
                <h2>Web Development</h2>
                <p>
                  A web development agency specializes in building, designing,
                  deploying, updating, and maintaining websites and web
                  applications.
                </p>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6 col-md-4 m-auto">
            <div className="card border-0 shadow text-center my-3">
              <div className="card-body service">
                <i>
                  <FaChartLine />
                </i>
                <h2>UI/UX Design</h2>
                <p>
                  UI/UX creates a connection between user and service provider,
                  improve customer satisfaction, improve user interaction, as
                  well as boost your business grow and enhance.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 m-auto">
            <div className="card border-0 shadow text-center my-3">
              <div className="card-body service">
                <i>
                  <FaLaptop />
                </i>
                <h2>App Devlopmemt</h2>
                <p>
                  Mobile application development is the set of processes and
                  procedures involved in writing software for small, wireless
                  computing devices,
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 m-auto">
            <div className="card border-0 shadow text-center my-3">
              <div className="card-body service">
                <i>
                  <FaUncharted />
                </i>
                <h2>Digital Marketing</h2>
                <p>
                  Digital Marketing refers to the activities of a company
                  associated with buying, advertising, distributing, or selling
                  a product or service.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 m-auto">
            <div className="card border-0 shadow text-center my-3">
              <div className="card-body service">
                <i>
                  <FaLaptop />
                </i>
                <h2>E-commerce </h2>
                <p>
                  E-commerce is the buying and selling of goods or services via
                  the internet, and the transfer of money and data to complete
                  the sales.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 m-auto">
            <div className="card border-0 shadow text-center">
              <div className="card-body service">
                <i>
                  <FaWaveSquare />
                </i>
                <h2>DevOps</h2>
                <p>
                  DevOps integrates and automates the work of software
                  development and IT operations as a means for improving and
                  shortening the systems development life cycle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Service;
